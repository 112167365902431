import React from 'react'
// import { Button } from 'antd'
// import { FormattedMessage } from 'react-intl'
// import HomeMenu from './HomeMenu'
// import ProjectManagement from './ProjectManagement'
// import IssuesHistory from './IssuesHistory'
// import LiveSearch from './LiveSearch'
// import BitcoinPrice from './BitcoinPrice'
import ProfileMenu from './ProfileMenu'
// import LanguageSelector from './LanguageSelector'
import styles from './style.module.scss'
import Breadcrumbs from '../Breadcrumbs'

class TopBar extends React.Component {
  render() {
    return (
      <div className={styles.topbar}>
        {/* <div className="mr-4">
          <IssuesHistory />
        </div>
        <div className="mr-4">
          <ProjectManagement />
        </div> */}
        <div className="d-none d-md-block mr-4">
          <Breadcrumbs />
        </div>
        <div className="mr-auto" />

        <div className="mr-4" />
        <div className="mr-4" />

        <div className="mr-4" />
        <ProfileMenu />
      </div>
    )
  }
}

export default TopBar
