import data from '../pages/team/view/data.json'

export async function getLeftMenuData() {
  const playersMenu = []
  data.players.DEFENDERS.map(player =>
    playersMenu.push({
      title: `${player.nameOne.charAt(0).toUpperCase()}${player.nameOne
        .substring(1)
        .toLowerCase()} ${player.nameTwo}`,
      key: player.nickname,
      url: `/player/profile/${player.nickname}`,
    }),
  )
  data.players.FORWARDS.map(player =>
    playersMenu.push({
      title: `${player.nameOne} ${player.nameTwo}`,
      key: player.nickname,
      url: `/player/profile/${player.nickname}`,
    }),
  )
  data.players.GOALKEEPERS.map(player =>
    playersMenu.push({
      title: `${player.nameOne.charAt(0).toUpperCase()}${player.nameOne
        .substring(1)
        .toLowerCase()} ${player.nameTwo}`,
      key: player.nickname,
      url: `/player/profile/${player.nickname}`,
    }),
  )
  data.players.INJURED.map(player =>
    playersMenu.push({
      title: `${player.nameOne.charAt(0).toUpperCase()}${player.nameOne
        .substring(1)
        .toLowerCase()} ${player.nameTwo}`,
      key: player.nickname,
      url: `/player/profile/${player.nickname}`,
    }),
  )
  data.players.MIDFIELDERS.map(player =>
    playersMenu.push({
      title: `${player.nameOne.charAt(0).toUpperCase()}${player.nameOne
        .substring(1)
        .toLowerCase()} ${player.nameTwo}`,
      key: player.nickname,
      url: `/player/profile/${player.nickname}`,
    }),
  )
  data.players['ON LOAN'].map(player =>
    playersMenu.push({
      title: `${player.nameOne.charAt(0).toUpperCase()}${player.nameOne
        .substring(1)
        .toLowerCase()} ${player.nameTwo}`,
      key: player.nickname,
      url: `/player/profile/${player.nickname}`,
    }),
  )

  return [
    {
      title: 'Team Overview',
      key: 'TeamOverview',
      url: '/team/view',
      icon: 'icmn icmn-home',
    },
    {
      title: 'Analytics Tool',
      key: 'analytics_tool',
      url: '/analytics',
      icon: 'icmn icmn-stats-dots',
    },
    {
      title: 'Team Overview',
      key: 'team',
      url: '/team/view',
      icon: 'icmn icmn-home',
      display: false,
      children: [
        {
          title: 'Player Profile',
          key: 'playerprofile',
          icon: '',
          children: playersMenu,
        },
      ],
    },
    {
      title: 'Calendar',
      key: 'events',
      icon: 'icmn icmn-calendar',
      url: '/event',
    },
    {
      title: 'Calendar',
      key: 'events',
      icon: 'icmn icmn-calendar',
      url: '/event',
      display: false,
      children: [{ title: 'Add Event', key: 'addevent', url: '/event/add' }],
    },
    {
      title: 'Settings',
      key: 'settings',
      icon: 'icmn icmn-cogs',
      children: [
        {
          title: 'Team',
          key: 'team',
          icon: 'icmn icmn-profile',
          children: [
            {
              title: 'Manage Teams',
              key: 'team_manage',
              url: '/team',
            },
            {
              title: 'Manage Players',
              key: 'players_manage',
              url: '/player',
            },
          ],
        },
        {
          title: 'Users',
          key: 'users',
          icon: 'icmn icmn-users',
          children: [
            {
              title: 'Manage Users',
              key: 'users_manage',
              url: '/users',
            },
          ],
        },
        {
          title: 'Assign a new Device',
          key: 'device',
          icon: 'icmn icmn-list2',
          url: '/device/assign/add',
        },
      ],
    },
  ]
}
export async function getTopMenuData() {
  return [
    {
      title: 'Settings',
      key: 'settings',
      icon: 'icmn icmn-cog utils__spin-delayed--pseudo-selector',
    },
    {
      title: 'Docs',
      key: 'documentation',
      url: 'https://docs.cleanuitemplate.com/react/getting-started',
      target: '_blank',
      icon: 'icmn icmn-books',
    },
    {
      title: 'Dashboards',
      key: 'dashboards',
      icon: 'icmn icmn-stack',
      children: [
        {
          title: 'Dashboard Alpha',
          key: 'dashboardAlpha',
          url: '/dashboard/alpha',
        },
      ],
    },
  ]
}
