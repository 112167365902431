import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import Loadable from 'react-loadable'

import Loader from 'components/LayoutComponents/Loader'
import IndexLayout from 'layouts'
import NotFoundPage from 'pages/404'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // System Pages
  {
    path: '/user/login',
    component: loadable(() => import('pages/user/login')),
    exact: true,
  },
  {
    path: '/user/forgot',
    component: loadable(() => import('pages/user/forgot')),
    exact: true,
  },

  // Dashboards
  {
    path: '/dashboard/alpha',
    component: loadable(() => import('pages/team/view')),
    exact: true,
  },

  // Elite Athletes Routes
  {
    path: '/team/view/',
    component: loadable(() => import('pages/team/view')),
    exact: true,
  },
  {
    path: '/analytics',
    component: loadable(() => import('pages/customizedReport')),
    exact: true,
  },
  {
    path: '/player/profile/:slug',
    component: loadable(() => import('pages/profile')),
    exact: true,
  },

  //  Event Routes
  {
    path: '/event/',
    component: loadable(() => import('pages/event/manage')),
    exact: true,
  },
  {
    path: '/event/add',
    component: loadable(() => import('pages/event/add')),
    exact: true,
  },

  //  Device Routes
  {
    path: '/device/',
    component: loadable(() => import('pages/device/manage')),
    exact: true,
  },
  {
    path: '/device/add',
    component: loadable(() => import('pages/device/add')),
    exact: true,
  },
  {
    path: '/device/assign/add',
    component: loadable(() => import('pages/assignedDevices/add')),
    exact: true,
  },

  //  Users Routes
  {
    path: '/users/add',
    component: loadable(() => import('pages/user/add')),
    exact: true,
  },
  {
    path: '/users',
    component: loadable(() => import('pages/user/manage')),
    exact: true,
  },

  //  Player Routes
  {
    path: '/player',
    component: loadable(() => import('pages/player/manage')),
    exact: true,
  },
  {
    path: '/player/add',
    component: loadable(() => import('pages/player/add')),
    exact: true,
  },

  //  Team Routes
  {
    path: '/team',
    component: loadable(() => import('pages/team/manage')),
    exact: true,
  },
  {
    path: '/team/add',
    component: loadable(() => import('pages/team/add')),
    exact: true,
  },
]

class Router extends React.Component {
  render() {
    const { history } = this.props
    return (
      <ConnectedRouter history={history}>
        <IndexLayout>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/team/view" />} />
            {routes.map(route => (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </IndexLayout>
      </ConnectedRouter>
    )
  }
}

export default Router
