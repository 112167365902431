import React from 'react'
import { Layout } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import styles from './style.module.scss'

@withRouter
export default class LoginLayout extends React.PureComponent {
  render() {
    const { children } = this.props

    return (
      <Layout>
        <Layout.Content>
          <div
            className={styles.layout}
            style={{
              backgroundImage: `url('resources/images/photos/7.jpg')`,
              backgroundPosition: 'center',
            }}
          >
            <center>
              <div className={styles.header}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className={styles.logo}>
                      <Link to="/" />
                    </div>
                  </div>
                </div>
              </div>
            </center>
            <div className={styles.content}>{children}</div>
            <div className={`${styles.footer} text-center`} style={{ color: 'white' }}>
              <p>© Copyright Circadia Technologies Ltd 2019. All rights reserved.</p>
            </div>
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}
